
import Storage from "@/util/Storage"
import {
    delFriendComment, delFriendSuc,
    friendCommentGive,
    friendCommentList,
    friendGive,
    friendSuc,
    friendSucComment
} from "@/Api/Friend";
import {FriendSuccessStore} from "@/views/Friend/FriendSuccess/Store/index";
import {setCommentType} from "@/views/Friend/FriendSuccess/Store/indexType";
import {Toast} from "vant";

/** 查询详情 */
export function getFriendSuccess( id:string|number ) {
    const userId = Storage.GetData_ && Storage.GetData_("userId");
    const token = Storage.GetData_ && Storage.GetData_("token");
    return Promise.resolve( friendSuc({userId,token},{ id }).then(res=>{
        return res.data
    }) )
}

/** 评论列表 */
export function commentList() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(friendCommentList({ userId,token },FriendSuccessStore.getUpDataSuc).then(res=>{
        return res.data
    }))
}

/** 点赞 */
export function getGive(id: string|number ) {
    let body = { momentsId:id }
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(friendGive({ userId,token },body).then(res=>{
        return res.data
    }))
}

/** 评论点赞 */
export function commentGive(id: string|number ) {
    let body = { commentId:id }
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(friendCommentGive({ userId,token },body).then(res=>{
        return res.data
    }))
}

/** 删除评论 */
export function DelComment(id: string|number ) {
    let body = { id }
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(delFriendComment({ userId,token },body).then(res=>{
        return res.data
    }))
}

/** 评论 */
export function comment(str: string ) {
    let data = FriendSuccessStore.getCommentData
    let body:setCommentType = {momentsId:data.momentsId,content:str }
    if ( data.secondComment ){
        body.toId = data["toId"];
    }
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(friendSucComment({ userId,token },body).then(res=>{
        return res.data
    }))
}

/** 删除朋友圈 */
export function DelFriends(id: string|number ) {
    let body = { id }
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    Toast.loading({ duration:0,message:"删除中~~",forbidClick:true })
    return Promise.resolve(delFriendSuc({ userId,token },body).then(res=>{
        Toast.clear()
        return res.data
    }))
}
